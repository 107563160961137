<template>
    <div>
        <!-- 底部区域 -->
	    <div class="footer">
	      <div class="container">
	        <p><a href="/">首页</a>|<a href="/product/3685/3694">产品中心</a>|<a href="/service/3689/3715">服务支持</a>|<a href="/aboutUs/3691/3726">关于我们</a></p>
	        <p>全国咨询热线：027-87180285 <a href="tel:027-87180285" data-agl-cvt="2">立即拨打</a></p>
	        <p>武汉武钢华工激光大型装备有限公司  版权所有</p>
	        <p><a href="http://www.miitbeian.gov.cn/" target="_blank">鄂ICP备12016925号-1</a></p>
	        <p><a href="http://www.beian.gov.cn/" target="_blank">鄂公网安备 42018502003559号</a></p>
	      </div>
	    </div>

	    <!-- 底部按钮 -->
	    <div class="menu" style="z-index: 1000;">
	      <ul>
	        <li><a href="tel:027-87180285" data-agl-cvt="2"><i style="background-image:url(https://res.baowugroup.com/wghglaser.com/public/themes/jiguang/public/mobile/images/home/15.png);"></i>服务电话</a></i></li>
	        <li><a href="http://p.qiao.baidu.com/cps/chat?siteId=8368088&userId=5878546"><i style="background-image:url(https://res.baowugroup.com/wghglaser.com/public/themes/jiguang/public/mobile/images/home/16.png);"></i>免费咨询</a></li>
	        <li><a href="http://p.qiao.baidu.com/cps/chat?siteId=8368088&userId=5878546"><i style="background-image:url(https://res.baowugroup.com/wghglaser.com/public/themes/jiguang/public/mobile/images/home/17.png);"></i>在线订购</a></li>
	      </ul>
	    </div>

	    <!-- 回到顶部 -->
	    <div data-am-widget="gotop" class="am-gotop am-gotop-fixed">
	      <a href="#top" title="">
	        <img class="am-gotop-icon-custom" src="https://res.baowugroup.com/wghglaser.com/public/themes/jiguang/public/mobile/images/home/14.png" />
	      </a>
	    </div>
	    <div class="cover"></div>
    </div>
</template>
<script>
export default {
    data () {
        return {
            url: 'https://www.wghglaser.com/',
            link:'',
        }
    },

    created () {
    },

    mounted () {

    },
    methods: {
        getLink(){
            this.$router.push(this.link)
        },
        goChat () {
            window.open('https://affim.baidu.com/cps/chat?siteId=2298312&userId=5878546&siteToken=3ad89c91612a1981745174b1dc92911f')
        },
        goTeler () {
            this.$refs.tels.href = `tel:02787180285`
            this.$refs.tels.click()
        },
        scrollToTop () {
            const scrollStep = window.pageYOffset / 20
            // 创建一个定时器
            const scrollInterval = setInterval(() => {
                // 如果已经滚动到了顶部，清除定时器
                if (window.pageYOffset === 0) {
                    clearInterval(scrollInterval)
                }
                // 滚动到上一帧的位置
                window.scroll(0, window.pageYOffset - scrollStep)
            }, 15)
        }
    }
}
</script>