<template>
    <div id="app">
        <hsHeader />
        <div class="layout">
            <transition name="fade" :duration="150">
                <router-view />
            </transition>
        </div>
        <hsFooter />
    </div>
</template>

<script>
import hsHeader from '@/components/public/heads.vue'
import hsFooter from '@/components/public/footers.vue'
export default {
    name: 'App',
    provide () {
        return {
            
        }
    },
    data () {
        return {
            data:'',
        }
    },
    components: {
        hsHeader,
        hsFooter
    },
    methods:{
        
    },
}
</script>

<style>
#app {
    width: 100%;
}
</style>
