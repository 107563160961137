import Vue from 'vue'
import Router from 'vue-router'
const originalPush = Router.prototype.push
Router.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}
const product = () =>
    import ('@/page/product');
Vue.use(Router)
export default new Router({
    mode: 'history',
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition && to.meta.keepAlive) {
            return savedPosition;
        }
        return { x: 0, y: 0 };
    },
    routes: [{
            path: '/',
            name: 'index',
            component: (resolve) => require(['@/page/index'], resolve),
            meta: {
                title: '首页'
            }
        },
        {
            path: '/product/:nodeId/:cnodeId',
            name: 'product',
            component: product,
            meta: {
                title: '产品分类'
            }
        },
        {
            path: '/processing/:nodeId',
            name: 'processing',
            component: (resolve) => require(['@/page/processing'], resolve),
            meta: {
                title: '加工中心'
            }
        },
        {
            path: '/productDetails/:nodeId/:cnodeId',
            name: 'productDetails',
            component: (resolve) => require(['@/page/productDetails'], resolve),
            meta: {
                title: '产品详情'
            }
        },
        {
            path: '/processingDetails/:nodeId/:cnodeId',
            name: 'processingDetails',
            component: (resolve) => require(['@/page/productDetails'], resolve),
            meta: {
                title: '加工详情'
            }
        },

        {
            path: '/aboutUs/:nodeId/:cnodeId',
            name: 'aboutUs',
            component: (resolve) => require(['@/page/aboutUs'], resolve),
            meta: {
                title: '关于我们'
            }
        },
        {
            path: '/newsList/:nodeId/:cnodeId',
            name: 'newsList',
            component: (resolve) => require(['@/page/newsList'], resolve),
            meta: {
                title: '新闻中心'
            }
        },
        {
            path: '/article/:type/:nodeId/:cnodeId',
            name: 'article',
            component: (resolve) => require(['@/page/article'], resolve),
            meta: {
                title: '文章详情'
            }
        },
        {
            path: '/service/:nodeId/:cnodeId',
            name: 'service',
            component: (resolve) => require(['@/page/service'], resolve),
            meta: {
                title: '服务支持'
            }
        },
        {
            path: '/case/:nodeId',
            name: 'case',
            component: (resolve) => require(['@/page/case'], resolve),
            meta: {
                title: '行业案例'
            }
        },
        {
            path: '/technology/:nodeId',
            name: 'technology',
            component: (resolve) => require(['@/page/case'], resolve),
            meta: {
                title: '工艺中心'
            }
        },
        {
            path: '/info/:type/:nodeId',
            name: 'caseInfo',
            component: (resolve) => require(['@/page/caseInfo'], resolve),
            meta: {
                title: '案例详情'
            }
        },
        {
            path: '/search',
            name: 'search',
            component: (resolve) => require(['@/page/search'], resolve),
            meta: {
                title: '搜索'
            }
        },
        {
            path: '/terms',
            name: 'terms',
            component: (resolve) => require(['@/page/terms'], resolve),
            meta: {
                title: '使用条款'
            }
        },

    ]
})